<template>
  <div class="relative z-10 step1 text-center h-full mx-6">
    <div class="self-end">
      <h5 class="text-pink-cs text-base">نوع کاربری ملک</h5>
      <span class="text-white mt-8 mb-16 text-xs">نوع کاربری ملک خودتو مشخص کن</span>
    </div>
    <div class="radio step-3-radio">
      <div
          class="radio__group"
          v-for="(item,i) in houseUsages"
          :key="i"
      >
        <input type="checkbox" class="radio__button" name="typeAds" v-model="checked" :value="item">
        <label  class="radio__title">{{ item }}</label>
      </div>
    </div>
    <div class="w-full self-start">
      <a class="btn-custom" @click="next">
        بررسی و ادامه
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
         <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import { mapState } from "vuex";

export default {
  name: "Step2",
  data(){
    return{
      houseUsages : null,
      checked : []
    }
  },
  methods :{
    next(){
      console.log(this.checked)
      if (this.checked === null || this.checked.length <= 0){
        this.$toast.error('حتما یک مورد را باید انتخاب کنید')
      }else {
        this.$store.dispatch('addEstate/getHouseUsages',this.checked)
        this.$router.push({name : 'AddEstateStep3'})
      }

    }
  },
  computed : {
    ...mapState({
      usage : state => state.addEstate.usage
    })
  },
  mounted() {
    axios.get('options/types-and-usages')
    .then(({data})=>{
      const houseUsages = data.data.filter(item => item.id === 'houseUsages')
      this.houseUsages = houseUsages[0].value
    })
    if (this.usage !== []){
      this.checked = this.usage
    }
  }
}
</script>

<style scoped>

</style>