<template>
  <div class="relative z-10 text-center step1 h-full  mx-6">
    <div class="self-end">
      <h5 class="text-pink-cs text-base">ثبت آگهی</h5>
      <span class="text-white mt-8 mb-16 text-xs">نوع آگهی  خودتو مشخص کن</span>
    </div>
    <div class="radio self-start">
      <div class="radio__group" v-for="(item,i) in addType" :key="i">
        <input type="radio" class="radio__button" name="typeAds" v-model="selected" :value="item.name">
        <label  class="radio__title">{{ item.translation }}</label>
      </div>
    </div>
    <div class="w-full self-center">
      <a class="btn-custom" @click="next">
        بررسی و ادامه
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import {mapState} from "vuex";

export default {
  name: "Step1",
  data(){
    return{
      addType : null,
      selected : null
    }
  },
  methods :{
    next(){
      if (this.selected === null || this.selected === ''){
        this.$toast.error('حتما یک مورد را باید انتخاب کنید')
      }else {
        this.$store.dispatch('addEstate/getAdvertisement',this.selected)
        this.$router.push({name : 'AddEstateStep2'})
      }
    }
  },
  computed : {
    ...mapState({
      advertisement_type : state => state.addEstate.advertisement_type
    })
  },
  mounted() {
    axios.get('options/ad-types')
        .then(({data})=>{
          this.addType = data.data
        })
    if (this.advertisement_type !== ''){
      this.selected = this.advertisement_type
    }
  }
}
</script>

<style scoped>

</style>