<template>
  <div class="relative z-10 text-center flex justify-center flex-col h-screen mx-6">
    <h5 class="text-pink-cs text-base">لینک ویدئو </h5>
    <div class="text-center text-white text-sm mt-8">
      <label>لینک ویدئو ملکتو اینجا وارد کن</label>
      <input
          type="text"
          class="input-border text-center mt-6"
          placeholder="لینک ویدیو"
          v-model="linkVideo"
      >
    </div>
    <div class="next-btn mb-24">
      <a class="btn-custom" @click="finish">
        ثبت ملک
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from "../../plugins/axios";
export default {
  name: "EditStep7",
  data : ()=>({
    linkVideo : ''
  }),
  computed : {
    ...mapState(['addEstate'])
  },
  methods : {
    finish(){
      axios.post('/estates',{
        ...this.addEstate,
        video_link : this.linkVideo
      }).then(({data})=>{
        if (!data.status){
        }else{
          this.$store.commit('general/CHANGE_IS_SHOW_BACK' , false)
          this.$router.push({name : 'AddEstateFinish'})
        }
      }).catch((err)=>{
        this.$toast.error('لینک ویدیو مشکل دارد')
      })
    }
  },

}
</script>

<style scoped>

</style>