<template>
  <div class="mt-7 text-right">
    <span class="text-white text-xs pr-3">{{ title }}</span>
    <div class="row-radios scrollbar-hide" ref="scroll" @wheel="handleWheel">
      <div v-for="(countPlus , i) in countPlus">
        <input
            type="radio"
            :name="name"
            :checked="i ==  checked"
            class="radio__button"
            @change="$emit('change', $event.target.value)"
            :value="i"
        >
        <label>{{ i }}</label>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SelectInput",
  data() {
    return {
      countPlus: this.count + 1
    }
  },
  model: {
    event: 'change'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    checked :{
      type : [Number,String],
      default : 0
    }
  },
  methods: {
    handleWheel(event) {
      setTimeout(() => {
        if (event.deltaY < 0) {
          this.$refs.scroll.scrollLeft -= 50;
        } else if (event.deltaY > 0) {
          this.$refs.scroll.scrollLeft += 50;
        }
      }, 200)
    },

  },

}
</script>

<style>

</style>