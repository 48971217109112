<template>
  <div class="checkbox_wrapper">
    <div class="checkbox-group" v-for="item in items">
      <input
          type="checkbox"
          class="checkbox-input-custom"
          :value="item"
          v-model="proxyChecked"
      >
      <label>{{item}}</label>
    </div>

  </div>
</template>

<script>
export default {
  name: "checkboxInput",
  props:{
    items :{
      type : Array,
      required : true
    },
    checked: {
      type: Array,
      default: false
    },
  },
  model: {
    prop: "checked",
    event: "change"
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  },
  methods :{

  }
}
</script>

<style scoped>

</style>