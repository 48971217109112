<template>
  <div class="relative z-10 text-center flex justify-center flex-col h-screen mx-6">
    <h5 class="text-pink-cs text-base">عکس</h5>
          <div class="text-center ">
          <p class="text-sm text-white mt-8">عکس کاور  ملک رو  انتخاب کنید</p>
          <div class="images-profile">
            <Uploader
                v-model="coverImg"
                :autoUpload="false"
                title=""
                :limit="1"
                @on-change="handleCover"
                class="upload-component"
                :limitPrompt="l => `تعداد آپلود مجاز ${l} می باشد`"
            />
          </div>
        </div>
        <div class="text-center ">
          <p class="text-sm text-white mt-8">عکس  های ملک رو اینجا آپلود کن</p>
          <div class="images-profile">
            <Uploader
                v-model="images"
                :autoUpload="false"
                title=""
                :limit="8"
                @on-change="handleImages"
                class="upload-component"
                :limitPrompt="l => `تعداد آپلود مجاز ${l} می باشد`"
            />
          </div>
        </div>
        <div class="mt-12">
          <a class="btn-custom" @click="next">
            ثبت و ادامه
            <span class="svg-btn-icon2 bg-orange transform rotate-0">
         <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
          </a>
        </div>
  </div>
</template>

<script>
import Uploader from "vux-uploader-component";

export default {
  name: "EditStep6",
  components :{
    Uploader
  },
  data(){
    return{
      coverImg : [],
      images : [],
      coverBase64 : null,
      imagesBase64 : [],

    }
  },
  methods:{
    handleCover(){
      let img = this.coverImg[0].blob
      let reader = new FileReader();
      reader.onloadend  = (file) => {
        this.coverBase64 = reader.result
      };
      reader.readAsDataURL(img);
    },
    handleImages(){
      this.images.forEach((item)=>{
        this.imagesBase64 = []
        let blob = item.blob
        let reader = new FileReader();
        reader.onloadend  = (file) => {
          this.imagesBase64.push(reader.result);
        };
        reader.readAsDataURL(blob);
      })

    },
    next(){
      if (this.coverBase64 === null){
        this.$toast.error('لطفا عکس کاور را انتخاب کنید')
      }else{

        const data = {
          coverBase64 : this.coverBase64,
          imagesBase64 : this.imagesBase64
        }
        this.$store.dispatch('addEstate/getImages', data)
        this.$router.push({name : 'AddEstateStep7'})
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.upload-component{
  padding: unset ;
}
.upload-component >>> .vux-uploader_hd{
  display: none !important;
}
.upload-component >>> .vux-uploader_input-box{
  float: right !important;
  background: rgba(255,255,255,0.2);
  border-radius: 1.5rem;
  border: none;
}
.upload-component >>> .vux-uploader_input-box::after{
  content: '';
  background: url("../../assets/images/icons/shot_image.svg") center center no-repeat;
  width: 100%;
  height: 100%;
}
.upload-component >>> .vux-uploader_input-box::before{
  all: revert;
}
.upload-component >>> .vux-uploader_file{
  float: right !important;
  border-radius: 1.5rem;
}
</style>